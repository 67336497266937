.tixxt__form--actions {
  @apply flex flex-col-reverse sm:flex-row gap-2 justify-end;
}

/* Select Styling */
.form-select__control {
  @apply border-box py-1.5 px-3;
}

.form-select__control--is-focused {
  @apply border-primary;
  box-shadow: inset 0 0 0 0.25rem rgba(var(--tailwind-primary) / 0.25);
}

.form-select__control--is-disabled {
  @apply text-muted;
  background: var(--btn-light--hover-background);
}

.form-select__placeholder {
  @apply text-muted;
}

.form-select__value-container {
  @apply gap-1;
}

.form-select__multi-value {
  @apply rounded px-1.5 py-1 text-sm gap-1;
  background-color: var(--tag-background);
  color: var(--tag-text);
}

.form-select__menu {
  @apply border-box mt-1.5 absolute z-10 !important; /* absolute, z-index and !important are needed to avoid menu to slide behind buttons */
}

.form-select__group-heading {
  @apply uppercase text-muted text-xs pt-3 pb-1.5 px-3;
}

.form-select__option,
.form-select__menu-notice {
  @apply py-1.5 px-3;
}

.form-select__option:hover {
  @apply cursor-pointer;
  color: var(--userbar-link--hover-text);
  background-color: var(--userbar-link--hover-background);
}

.form-select__option--is-selected,
.form-select__option--is-selected:hover {
  color: var(--userbar-link--active-text);
  background: var(--userbar-link--active-background);
}

.form-select__indicators {
  @apply -my-1.5 -mr-3;
}

.form-select__indicator-separator {
  @apply bg-neutral;
}
.form-select__indicator {
  @apply h-full text-muted px-3 hover:cursor-pointer items-center btn btn-light;
}

.form-select__control--is-focused .form-select__indicator:hover {
  box-shadow:
    inset 0 0.25rem 0 0 rgba(var(--tailwind-primary) / 0.25),
    inset 0 -0.25rem 0 0 rgba(var(--tailwind-primary) / 0.25);
}

.form-select__control--is-focused .form-select__indicator:last-child:hover {
  box-shadow:
    inset 0 0.25rem 0 0 rgba(var(--tailwind-primary) / 0.25),
    inset 0 -0.25rem 0 0 rgba(var(--tailwind-primary) / 0.25),
    inset -0.25rem 0 0 0 rgba(var(--tailwind-primary) / 0.25);
}

.form-select__indicator:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
}

.form-select__clear-indicator {
  @apply text-danger hover:text-danger border-l border-l-neutral;
}

.form-select__input:focus {
  @apply shadow-none border-none border-0;
}

.remove-input-txt-border input[type="text"]:focus,
input.form-select__input[type="text"]:focus {
  box-shadow: none;
}

/* Field Errors */
.field-error .form-control,
input.field-error,
textarea.field-error {
  @apply border-danger !important;
}

input.field-error:focus,
textarea.field-error:focus {
  box-shadow: inset 0 0 0 0.25rem rgba(var(--tailwind-danger) / 0.25) !important;
}

.field-error .form-select__control--is-focused {
  @apply border-danger;
  box-shadow: inset 0 0 0 0.25rem rgba(var(--tailwind-danger) / 0.25);
}

.field-error .form-select__control {
  @apply border-danger;
}

.field-error .form-select__control--is-focused .form-select__indicator:hover {
  box-shadow:
    inset 0 0.25rem 0 0 rgba(var(--tailwind-danger) / 0.25),
    inset 0 -0.25rem 0 0 rgba(var(--tailwind-danger) / 0.25);
}

.field-error
  .form-select__control--is-focused
  .form-select__indicator:last-child:hover {
  box-shadow:
    inset 0 0.25rem 0 0 rgba(var(--tailwind-danger) / 0.25),
    inset 0 -0.25rem 0 0 rgba(var(--tailwind-danger) / 0.25),
    inset -0.25rem 0 0 0 rgba(var(--tailwind-danger) / 0.25);
}

.field-error .controls {
  @apply border-danger;
}

/* Disabled */
fieldset[disabled] label {
  @apply text-muted;
}

/* Danger Zone */
.danger-zone {
  max-width: calc(65ch + 200px + 1rem);
}
