.carousel__container {
  display: grid;
  grid-template-areas: "stack";
}

.carousel__button--prev,
.carousel__button--next {
  @apply z-50 text-primary/75 hover:text-primary mt-8;
  grid-area: stack;
}
.carousel__button--next {
  @apply justify-self-end mr-3;
  grid-area: stack;
}
.carousel__button--prev {
  @apply justify-self-start ml-3;
  grid-area: stack;
}

.carousel__content {
  grid-area: stack;
}
